import React, { useState } from "react";
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./index.scss";
import * as yup from "yup";
import customerApi from "../../apis/api/customer";
import { useDispatch } from "react-redux";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
} from "../../reduxs/guide-session/action";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CreateUserProfileModal = (props) => {
  const { startDiscover } = props;
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState();
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [role, setRole] = useState();
  const [mobile, setMobile] = useState();
  const [error, setError] = useState();
  const notify = (message) => toast.info(message);
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );
  const isShowCreateInvestorProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateInvestorProfileModal
  );
  const isShowCreateTenantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateTenantProfileModal
  );
  const isShowCreateConsultantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateConsultantProfileModal
  );

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
    company: yup.string().required(),
    role: yup.string().required(),
    mobile: yup.number().required(),
  });

  const handleCreateCustomer = async () => {
    try {
      let userGroup = '606d6f356af09f50f367ee23';

      if (isShowCreateTenantProfileModal) {
        userGroup = '610a4a3351400773f94bfa89'
      }

      if (isShowCreateConsultantProfileModal) {
        userGroup = '63030d2f94bc7bf551da79e3'
      }

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        name: name,
        company: company,
        role: role,
        mobile: mobile,
      };

      const result = await validationSchema.validate(data);
      const res = await customerApi.createCustomer(data);

      analytics.track('Agent Created Client', {
        agentId: authUser.id,
        clientId: res.data?.id,
        clientName: res.data?.name,
        clientEmail: res.data?.email,
        clientPhone: res.data?.mobile,
      });

      history.push({
        search: `?customer=${res?.data?.id}`
      });

      if (isShowCreateInvestorProfileModal) {
        dispatch(reqSetIsShowCreateInvestorProfileModal(false));
        notify("Investor created successfully!");
      }
      if (isShowCreateTenantProfileModal) {
        dispatch(reqSetIsShowCreateTenantProfileModal(false));
        notify("Tenant created successfully!");
      }
      if (isShowCreateConsultantProfileModal) {
        dispatch(reqSetIsShowCreateConsultantProfileModal(false));
        notify("Consultant created successfully!");
      }
      handleResetData();
      startDiscover();
    } catch (err) {
      setError(err);
    }
  };

  const handleClose = () => {
    history.push({
      search: '',
    });
    if (isShowCreateInvestorProfileModal) {
      dispatch(reqSetIsShowCreateInvestorProfileModal(false));
      dispatch(reqSetIsShowListInvestorModal(true));
    }
    if (isShowCreateTenantProfileModal) {
      dispatch(reqSetIsShowCreateTenantProfileModal(false));
      dispatch(reqSetIsShowListTenantModal(true));
    }
    if (isShowCreateConsultantProfileModal) {
      dispatch(reqSetIsShowCreateConsultantProfileModal(false));
      dispatch(reqSetIsShowListConsultantModal(true));
    }
    handleResetData();
  };

  const handCloseAllModal = () => {
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    handleResetData();
  };

  const handleResetData = () => {
    setEmailAddress("");
    setName("");
    setCompany("");
    setRole("");
    setMobile("");
  };
  return (
    <Modal
      className="wrap-create-user-profile-modal"
      show={isShowCreateInvestorProfileModal || isShowCreateTenantProfileModal || isShowCreateConsultantProfileModal}
      onHide={handCloseAllModal}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="modal-form__title">
          SET UP A GUIDED SESSION USING THE
        </div>
        <div className="modal-form__title underline-sm mb-4">
          DISTRICT DOCKLANDS EXPERIENCE APP
        </div>
        <p className="mb-4 modal-form__sub-title">Create a new {userSelectType} profile</p>
        <div className="mb-4">
          <InputGroup className="form-group">
            <FormControl
              id="email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Email Address"
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="name"
              name="name"
              className="form-control"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="company"
              name="company"
              className="form-control"
              placeholder="Company Name"
              onChange={(e) => setCompany(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="role"
              name="role"
              className="form-control"
              placeholder="Role"
              onChange={(e) => setRole(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="mobile"
              name="mobile"
              className="form-control"
              placeholder="Phone Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </InputGroup>
          <div className="mt-3">
            <span className="label">User Type:</span>
            <span className="type-value">{userSelectType}</span>
          </div>
        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button className="text-uppercase" onClick={handleClose}>Cancel</button>
          <button className="text-uppercase" onClick={handleCreateCustomer}>Start Session</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserProfileModal;
