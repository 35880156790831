import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reqGetListGalleries, reqSetEditableGallery, reqSetIsShowDeleteGalleryModal, reqSetSelectedMedia } from "../../reduxs/cms/action";
import Select from 'react-select';
import MediaPicker from "./media-picker";
import galleryApi from "../../apis/api/gallery";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getImagePath } from "../../helper/media";

const EditGalleryModal = () => {
  const fileRef = useRef();
  const editableGallery = useSelector((state) => state.cms.editableGallery);
  const selectedMedia = useSelector((state) => state.cms.selectedMedia);
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [type, setType] = useState('images');
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (editableGallery) {
      setName(editableGallery.name);
      setType(editableGallery.type);
      setVideoUrl(editableGallery.videoUrl);
      dispatch(reqSetSelectedMedia(editableGallery.media.map(item => item.id)));
    }
  }, [editableGallery]);

  const handleClose = () => {
    dispatch(reqSetEditableGallery(false));
    resetForm();
  }

  const resetForm = () => {
    setName();
    setFile();
    setType('image');
    setVideoUrl();
    fileRef.current.value = "";
    dispatch(reqSetSelectedMedia([]));
  }

  const onOpenFileInput = () => {
    fileRef.current.click();
  }

  const onSaveGallery = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('file', file);
      formData.append('type', type);
      formData.append('videoUrl', videoUrl);

      selectedMedia.forEach((el, index) => {
        formData.append(`media[${index}]`, el);
      });

      await galleryApi.updateGallery(editableGallery.id, formData);

      dispatch(reqSetEditableGallery(false));
      dispatch(reqGetListGalleries());
      toast.success('Updated gallery successfully!');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const onDeleteGallery = () => {
    dispatch(reqSetIsShowDeleteGalleryModal(true));
  }

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return getImagePath(editableGallery?.thumbnail);
  }

  const renderImagePicker = () => {
    if (type === 'video') return;

    return (
      <div className="row">
        <label className="title">GALLERY IMAGES</label>
        <MediaPicker />
      </div>
    )
  }

  const renderVideoURLForm = () => {
    if (type !== 'video') return;
    return (
      <>
        <label className="title">VIDEO URL</label>
        <input value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} className="form-control" type="text" />
      </>
    )
  }

  return (
    <Modal
      size="lg"
      className="wrap-create-gallery-modal"
      show={!!editableGallery}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <div className="modal-form__title mb-4">
          EDIT GALLERY
        </div>
        <div className="content">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="title">UPLOAD THUMBNAIL</div>
              <img onClick={onOpenFileInput} className="gallery-image cursor-pointer" src={renderImagePreview()} alt="" />
              <input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => setFile(e.target.files[0])} />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <label className="title" htmlFor="">GALLERY TITLE</label>
              <input value={name} onChange={(e) => setName(e.target.value)} className="form-control mb-3" type="text" />
              <label className="title">GALLERY TYPE</label>
              <Select
                className="wrap-select-gallery-type mb-3"
                classNamePrefix="select"
                defaultValue={{
                  value: type.toLocaleLowerCase(),
                  label: type.toUpperCase(),
                }}
                isDisabled={false}
                isSearchable={true}
                options={[
                  {
                    value: 'images',
                    label: 'IMAGES',
                  },
                  {
                    value: 'virtual_tour',
                    label: 'VIRTUAL_TOUR',
                  },
                  {
                    value: 'video',
                    label: 'VIDEO',
                  },
                ]}
                name="type"
                onChange={(item) => setType(item.value)}
              />
              {renderVideoURLForm()}
            </div>
          </div>
          {renderImagePicker()}
        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button className="text-danger" onClick={onDeleteGallery}>DELETE</button>
          <button onClick={onSaveGallery}>SAVE</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditGalleryModal;
