import React, { useEffect, useRef } from 'react';
import './style.scss';
import { PAGES } from '../../constants/options';
import { useState } from 'react';
import LoadingIcon from '../loading-icon';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetPage } from '../../reduxs/home/action';
import { reqSetIsShowCreateInvestorProfileModal, reqSetIsShowCreateTenantProfileModal, reqSetIsShowUserTypeModal } from '../../reduxs/guide-session/action';
import Cube from '../cube';
import UserTypeModal from '../guide-sesions/user-type-modal';
import ListUserSelectModal from '../guide-sesions/list-user-select-modal';
import CreateUserProfileModal from '../guide-sesions/create-user-profile-modal';
import { reqGetUserProfile } from '../../reduxs/user/action';
import { USER_GROUP } from '../../constants/master-data';

const Loading = (props) => {
  const { isLoading, setIsIntroduction } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const authUser = useSelector((state) => state.user.data)
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );
  const customer = useSelector((state) => state.user.customer);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email
      });
    }
  }, [authUser])

  const startDiscover = () => {
    //setIsIntroduction(true);
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    analytics.track('Agent Started Session', {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    })
  };

  const onExplore = () => {
    dispatch(reqSetIsShowUserTypeModal(true));
  }

  const onStartSession = () => {
    setShowVideo(true);
    videoRef.current.play();
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
  }

  const renderExploreButton = () => {
    if (!authUser) return;

    if ([USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(authUser?.userGroup.name)) {
      return (
        <button onClick={() => startDiscover()}>EXPLORE</button>
      )
    }

    if (authUser?.userGroup.name === USER_GROUP.ADMIN) {
      return (
        <button onClick={() => onExplore()}>EXPLORE</button>
      )
    }
  }

  return (
    <div id="loading-screen">
      <div id="loading-cube-wrapper">
        <Cube
          draggable={false}
          disabled={isLoading}
          onClick={startDiscover}
        />
      </div>
      <div className={`wrap-intro ${isShowVideo ? 'hide' : 'show'}`}>
        <div
          id="intro-video"
          style={{ backgroundImage: `url("./uploads/images/poster.png")` }}
        />

        <div className="intro-content">
          <div className="wrap-text b-fade-text">
            <img style={{ width: '500px' }} className="img-fluid" src="/images/d-logo.png" alt="" />
          </div>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div className="wrap-button">
              {renderExploreButton()}
            </div>
          )}
          {/* Used to cache video */}
          <div style={{ display: "none" }}>
            <video
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
              style={{ backgroundImage: `url("/images/video_bg.png")` }}
            >
              <source src="/uploads/videos/ASH2239_TheDistrict_OfflineEdit_ID_02.mov" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className={`wrap-slide-loading ${isShowVideo ? 'show' : 'hide'}`}>
        {
          // isShowVideo &&
          (<>
            <video
              ref={videoRef}
              // autoPlay={true}
              onEnded={() => startDiscover()}
              preload="auto"
              id="intro-video-2"
              style={{ backgroundImage: `url("/images/video_bg.png")` }}
            >
              <source src="/uploads/videos/ASH2239_TheDistrict_OfflineEdit_ID_02.mov" type="video/mp4" />

            </video>
            <button onClick={() => startDiscover()} className="btn-skip-video btn btn-light">SKIP VIDEO</button>
          </>)}
      </div>
      <UserTypeModal />
      <ListUserSelectModal startDiscover={onStartSession} />
      <CreateUserProfileModal startDiscover={onStartSession} />
    </div>
  );
};

export default Loading;
