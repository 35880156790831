import React, { useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faTimesCircle,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetIsShowBookingAppointmentForm, reqSetIsTransparent } from '../../reduxs/home/action';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { USER_GROUP } from '../../constants/master-data';
import { reqGetCustomerProfile, reqGetUserProfile } from '../../reduxs/user/action';
import preferenceApi from '../../apis/api/preference';
import { toast } from 'react-toastify';
import { reqGetCustomerPreference } from '../../reduxs/precinct-explore/action';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import jwtAuth from '../../apis/utils/jwtAuth';
import { reqSetIsShowPostSessionPopup } from '../../reduxs/guide-session/action';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

library.add(faVolumeUp, faEye, faLightbulb, faHeart, faEyeSlash, faUser, faSignOutAlt, faCalendarCheck, faTimesCircle);
const BottomNavigation = (props) => {

  const { handleClickEndGuideTenantSession, handleClickTenantEndSession } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const customerId = useQuery().get('customer');
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const preference = useSelector((state) => state.precinctExplore.customerPreference);
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const authUser = useSelector((state) => state.user.data);
  const isShowEndGuideTenantSession = useSelector((state) => state.home.isShowEndGuideTenantSession);
  const isShowBookingAppointmentForm = useSelector((state) => state.home.isShowBookingAppointmentForm);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, [])

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId]);

  const onClickUserIcon = () => {
    window.location.href = '/cms';
  }

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const handleShowPostSessionPopup = () => {
    analytics.track('Agent Ended Session', {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetIsShowPostSessionPopup(true));
  }

  const renderEndSessionBtn = () => {
    if (!customer) return;

    // if (customer?.userGroup?.name === USER_GROUP.INVESTOR) {
    //   return (
    //     <span
    //     onClick={() => handleClickTenantEndSession()}
    //     className='btn-text'>
    //       END GUIDED INVESTOR SESSION
    //     </span>
    //   );
    // }

    if (customer?.userGroup?.name === USER_GROUP.TENANT && !isShowEndGuideTenantSession) {
      return (
        <span
          className='btn-text'
          onClick={() => handleClickEndGuideTenantSession(customerId)}
        >END GUIDED TENANT SESSION
        </span>
      );
    }

    if (customer?.userGroup?.name === USER_GROUP.TENANT && isShowEndGuideTenantSession) {
      return (
        <span
          className='btn-text'
          onClick={() => handleClickTenantEndSession()}
        >END SESSION
        </span>
      );
    }

    return (
      <span
        className='btn-text'
        onClick={() => handleShowPostSessionPopup()}
      >
        END SESSION
      </span>
    )
  }

  const handleSavePreference = () => {
    if (!selectedUnit) {
      return toast.error('Please select an unit!')
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter(item => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        toast.success('Saved Preference successfully!');
        getCustomerPreference();
        analytics.track('Agent Saved Property', {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.mobile,
          propertyId: selectedUnit?.id
        })
      }).then(res => {
        dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      })
    }
  }

  const handleUnsavePreference = () => {
    if (!selectedUnit) {
      return toast.error('Please select an unit!')
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter(item => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        toast.success('Unsaved Preference successfully!');
        getCustomerPreference();
        analytics.track('Agent Unsaved Property', {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.phone,
          propertyId: selectedUnit?.id
        })
      }).then(res => {
        dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      });
    }
  }

  const renderStarBtn = () => {
    if (authUser?.userGroup?.name === USER_GROUP.ADMIN && !selectedUnit) {
      return (
        <span className="btn-item" onClick={() => onClickUserIcon()}>
          <FontAwesomeIcon icon={['fas', 'user']} size="2x" />
        </span>
      )
    }

    if ([USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(authUser?.userGroup?.name) || customer?.userGroup?.name === USER_GROUP.INVESTOR || !selectedUnit) {
      return;
    }

    const unitIds = getUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span className="btn-item" onClick={() => handleUnsavePreference()}>
          <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091Z" fill="white" />
          </svg>
        </span>
      )
    }

    return (
      <span className="btn-item" onClick={() => handleSavePreference()}>
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091V28.6091ZM14.9401 1.98907L11.4201 9.16907C11.2766 9.45834 11.0652 9.70856 10.804 9.89838C10.5428 10.0882 10.2395 10.212 9.92008 10.2591L2.01008 11.4091L7.74009 16.9691C7.97133 17.1959 8.14401 17.4755 8.2433 17.7838C8.34258 18.0921 8.36552 18.4199 8.3101 18.7391L6.96009 26.6091L14.0201 22.889C14.3057 22.7401 14.623 22.6623 14.9451 22.6623C15.2672 22.6623 15.5845 22.7401 15.8701 22.889L22.9501 26.6191L21.5901 18.7491C21.5347 18.43 21.5576 18.1021 21.6569 17.7938C21.7562 17.4855 21.9288 17.2059 22.1601 16.9791L27.8901 11.399L19.9801 10.269C19.6606 10.2219 19.3574 10.0982 19.0961 9.90839C18.8349 9.71857 18.6235 9.46835 18.4801 9.17908L14.9401 2.00909V1.98907Z" fill="white" />
        </svg>
      </span>
    );
  }

  const getUnitIds = () => {
    const units = preference?.units || [];

    return units.map(item => item.id);
  }

  const handleLogout = () => {
    jwtAuth.removeToken();
    history.push('/holding');
  }

  const handleShowMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(true));
  }

  const handleCloseMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(false));
  }

  const renderLogoutBtn = () => {
    if (!authUser) return;

    return (
      <span onClick={handleLogout} className='me-3 cursor-pointer' style={{ fontSize: '20px' }}>
        <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
      </span>
    );
  }

  const renderMakeAppointmentBtn = () => {
    if (!authUser) return;

    if (isShowBookingAppointmentForm) {
      return (
        <span onClick={handleCloseMakeAppointmentForm} className='me-2 cursor-pointer' style={{ fontSize: '20px' }}>
          <FontAwesomeIcon icon={['fas', 'times-circle']} />
        </span>
      )
    }

    return (
      <span onClick={handleShowMakeAppointmentForm} className='me-2 cursor-pointer' style={{ fontSize: '20px' }}>
        <FontAwesomeIcon icon={['fas', 'calendar-check']} />
      </span>
    )
  }

  return (
    <div className="wrap-bottom-navigation">
      <div className="left-group-btn">
        <span className="btn-item" onClick={() => dispatch(reqSetIsTransparent(!isTransparent))}>
          {isTransparent ? (
            <FontAwesomeIcon icon={['fas', 'eye-slash']} size="2x" />
          ) : (
            <FontAwesomeIcon icon={['fas', 'eye']} size="2x" />
          )}
        </span>
        {renderLogoutBtn()}
        {renderMakeAppointmentBtn()}
        {renderEndSessionBtn()}
      </div>
      <div className="right-group-btn">
        {renderStarBtn()}
      </div>
    </div>
  );
};

export default BottomNavigation;
